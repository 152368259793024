import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button, NavLink, Toast, ToastBody} from "reactstrap5";
import {useQuery} from "@apollo/client";
import UserAchievementDetailQuery from "../gql/query/achievements/userAchievementDetail.gql";
import UserAchievementNewQuery from "../gql/query/achievements/userAchievementNew.gql";
import {getAsset} from "./achievements";
import {useUser} from "./user";
import {FormattedMessage} from "react-intl";
import {NavLink as RNavLink} from "react-router-dom5";
import * as Config from "user/helpers/config";
import {generateUrl, routes} from "user/helpers/routes";

const AchievementToastContext = React.createContext({
    reloadNewAchievements: () => {
    },
});

export function useAchievementToast() {
    const {reloadNewAchievements} = useContext(AchievementToastContext);
    return reloadNewAchievements;
}

const AchievementToast = ({userAchievementId, frontend}) => {

    const [user] = useUser();
    const [open, setOpen] = useState(false);

    const {data} = useQuery(UserAchievementDetailQuery, {
        variables: {
            id: userAchievementId
        },
    });

    useEffect(() => {
        setOpen(true);
        window.setTimeout(() => {
            setOpen(false);
        }, 30000);

    }, [data?.userAchievement, setOpen]);

    if (!open || !data || !data.userAchievement) {
        return (<div></div>);
    }

    return (
        <Toast key={data.userAchievement.achievementRule.id} className={"m-3"}>
            <ToastBody>
                <img alt={data.userAchievement.achievementRule.achievementGroup.title}
                     src={getAsset(data.userAchievement.achievementRule.achievementGroup.achievementAsset.asset.toLowerCase(), user.monsterColor, user.monsterType)}
                />
                <NavLink tag={frontend ? 'a' : RNavLink}
                         href={frontend ? Config.get('basename') + generateUrl(routes.dashboardAchievements) : null}
                         to={frontend ? null : generateUrl(routes.dashboardAchievements)}
                         onClick={() => clickOnMenu(false)}>
                    <span>
                        <FormattedMessage
                        defaultMessage={"Získal jsi nový odznak"}/>{" "}<strong>{data.userAchievement.achievementRule.achievementGroup.title} - <FormattedMessage
                        defaultMessage={"Level"}/>&nbsp;{data.userAchievement.achievementRule.level}</strong>
                    </span>
                </NavLink>
                <Button type="button"
                        className="btn-close text-reset ms-auto m-2"
                        color={"link"}
                        onClick={() => setOpen(false)}/>
            </ToastBody>
        </Toast>
    );

};

export function AchievementToastProvider(props) {
    const [userAchievementIds, setUserAchievementId] = useState([]);
    const [user] = useUser();

    const addAchievements = (achievementNotifies) => {
        const ids = window.localStorage.getItem('achievementNotifies') ? window.localStorage.getItem('achievementNotifies') : [];
        const newIds = achievementNotifies.filter((notify) => {
            const id = notify.id;

            return ids.indexOf(id) < 0;
        }).map((notify) => notify.id);

        setUserAchievementId(newIds);
        window.localStorage.setItem('achievementNotifies', ids.concat(newIds));
    }

    const {refetch, data} = useQuery(UserAchievementNewQuery, {
        variables: {
            user: user.id
        },
        fetchPolicy: 'network-only',
    });

    const reloadNewAchievements = useCallback(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        window.addEventListener("message", (event) => {
            if (event.data.type != 'achievementNotifies') {
                return;
            }

            refetch();
        });
    }, [refetch]);

    useEffect(() => {

        if (!data || !data.list || !data.list.totalCount) {
            return;
        }

        addAchievements(data.list.nodes);
    }, [data?.list?.nodes]);


    const toasts = userAchievementIds.map((userAchievementId) => <AchievementToast
        frontend={props.frontend}
        userAchievementId={userAchievementId}/>);

    return (
        <AchievementToastContext.Provider value={{reloadNewAchievements}}>
            {props.children}
            <div className={"position-fixed top-0 end-0 achievement-toasts"}>
                {toasts}
            </div>
        </AchievementToastContext.Provider>
    )
}