
export const DEFAULT_ASSET = 'basic';

export const VARIANT_A = 'A';
export const VARIANT_B = 'B';
export const VARIANT_C = 'C';

export const VARIANTS = [VARIANT_A, VARIANT_B, VARIANT_C];
export const DEFAULT_VARIANT = VARIANT_A;

export const GREEN = 'GREEN';
export const BLUE = 'BLUE';
export const PINK = 'PINK';
export const YELLOW = 'YELLOW';

export const COLORS = [GREEN, BLUE, PINK, YELLOW];
export const DEFAULT_COLOR = GREEN;

export const MONSTER_COLORS_CLASS = {
    PINK: "c-pink",
    BLUE: "c-blue",
    GREEN: "c-green",
    YELLOW: "c-yellow",
}

const ASSETS = {
    kure: 1,
    ikona: 2,
    raminko: 3,
    stesticko: 4,
    celenka: 5,
    fawkes: 6,
    dalekohled: 7,
    nekonecno: 8,
    kniha: 9,
    hul: 10,
    cylindr: 11,
    kastan: 12,
    zezlo: 13,
    jablko: 14,
    stit: 15,
    cepice: 16,
    klobouk: 17,
    celovka: 18,
    pyramida: 19,
    kotatko: 20,
    svatozar: 21,
//Možná bude chtít nahradit na backendu
    geometrie: 8,
    zarovka: 24,
    selfie: 23,
    helma: 20,
    athena: 22,
    uuzoo: 100,
    vetnicek: 101,
    mekotvrdak: 102,
    nerozdelnicek: 103,
    kratichvil: 104,
    pizzlomil: 105,
    merilek: 106,
    pismochytil: 107,
    sloveslar: 108,
    zapomnetlivik: 109,
    pocitadlik: 110,
    desetnicek: 111,
    vahalik: 112,
    casomerilek: 113,
    geometrak: 114,
    jakykterycek: 115,
    prisudak: 116,
    darecek: 117,
    tradicnak: 118,
}

export const getAsset = (asset, color, variant) => {

    let assetType = DEFAULT_ASSET;

    if (asset && ASSETS[asset.toLowerCase()]) {
        assetType = ASSETS[asset.toLowerCase()];
    }

    if (!variant || VARIANTS.indexOf(variant) < 0) {
        variant = DEFAULT_VARIANT;
    }

    if (!color || COLORS.indexOf(color) < 0) {
        color = DEFAULT_COLOR;
    }

    return require('../images/achievements/var' + variant + '_' + color.toLowerCase() + '_' + assetType + '.svg');
};

export const getLevel = (level) => {
    return require('../images/achievements/level' + level + '.svg');
};